import $                from 'jquery'
import _                from 'underscore'
import Backbone         from 'backbone'
import Velocity         from 'velocity-animate'
import random             from 'canvas-sketch-util/random'

import fullExtend         from '../lib/Backbone.fullExtend'

Backbone.$ = $
Backbone.View.fullExtend = fullExtend

import Grid             from '../components/grid'
import HomepagePanel    from '../components/homepage-panel'
import Salon            from '../components/salon'
import SignupForm       from '../components/signup-form'

import breakpoints      from '../lib/breakpoints'
import getVUnit         from '../lib/getVUnit'
import dimensionsFromImageBlock from '../lib/dimensionsFromImageBlock'
import loadImageBlock   from '../lib/loadImageBlock'

const BasePage = Backbone.View.extend({
    events: {

    },

    bufferTemplate: _.template(`
        <div class="pageBuffer pageBuffer--top"></div>
        <% if(footer){ %><div class="pageBuffer pageBuffer--bottom"></div><% } %>
    `),

    initialize: function(){
        _.bindAll(this, 'onImageBlockLoad')

        var that = this

        this.grids = []
        this.forms = []
        this.homepagePanels = []
        this.salon = []

        this.model = new Backbone.Model({
        
        })
        
        this.pubSub = _.extend({}, Backbone.Events)
        this.pubSub.on('page:loaded', this.pageLoaded, this)

        TAVOLOZZA.pubSub.on('app:scroll', this.scroll, this)
        TAVOLOZZA.pubSub.on('app:resize', this.resize, this)
        TAVOLOZZA.pubSub.on('app:resize:last', this.resizeLast, this)
        TAVOLOZZA.pubSub.on('app:resize:imageBlockFill', this.resizeImageBlocks, this)

        // GRIDS
        this.$('.grid').each(function(){
            const $el = $(this)
            const gridID = that.idForGroup(that.grids)

            $el.attr('grid-id', gridID)

            that.grids.push({
                id: gridID,
                view: new Grid({el: $el})
            })
        })

        // HOMEPAGE PANELS
        this.$('.homepagePanel').each(function(){
            const $el = $(this)
            const panelID = that.idForGroup(that.homepagePanels)

            $el.attr('panel-id', panelID)

            that.homepagePanels.push({
                id: panelID,
                view: new HomepagePanel({el: $el, parent: that})
            })
        })

        // SALON
        this.$('.salon').each(function(){
            const $el = $(this)
            const salonID = that.idForGroup(that.salon)

            $el.attr('salon-id', salonID)

            that.salon.push({
                id: salonID,
                view: new Salon({el: $el, parent: that})
            })
        })

        // FORMS
        this.$('.subscribeForm').each(function(){
            const $el = $(this)
            that.forms.push(new SignupForm({el: $el}))
        })

        // IMAGE BLOCKS
        this.loadImageBlocks(this.$el)

        // SCROLL BUFFER
        if(this.hasColour()){
            this.$el.append(this.bufferTemplate({ footer: this.$('.pageCont.pageCont--white').length > 0 }))
        }

        this.initThis()
        
        this.resize()

        _.each([
            this.grids,
            this.homepagePanels,
            this.salon
        ], (_set, i) => {
            that.functionOnGroup(_set, 'layout')
        })

        this.resizeLast()
        this.resizeImageBlocks()

        this.initThisEnd()
    },

    initThis: function(){
        return false
    },

    initThisEnd: function(){
        return false;
    },

    loadImageBlocks: function($par){
        const that = this
        
        $par.find('.imageBlock').each(function(i){
            const $el = $(this)
            const $img = $el.find('img')
            const _hasDimensions = _.isFinite($img.attr('width')) && _.isFinite($img.attr('height'))

            loadImageBlock($el, !_hasDimensions ? that.onImageBlockLoad : null)
        })
    },

    idForGroup: function(group){
        return `${ group.length }-${ random.rangeFloor(100) }`
    },

    resize: function(){
        this.$el.css({'min-height': TAVOLOZZA.view.h})
        this.$('.pageCont').css({'min-height': this.contentHeight()})
    },

    resizeLast: function(){
        const that = this

        this.resizeInlineImageBlocks()
    },

    imageHeightGivenWidth($container, width){
        const $imageBlock = $container.find('.imageBlock')
        const $img = $imageBlock.find('img')
        
        const imgSize = dimensionsFromImageBlock($imageBlock)

        const _imgH = imgSize.height / imgSize.width * width
        return _imgH
    },

    resizeImageBlocks: function(){
        const that = this

        this.$('.imageBlock').each(function(){
            const $el = $(this)
            that.resizeImageBlock($el)
        })
    },

    resizeInlineImageBlocks: function(){
        const that = this

        this.$('.imageBlock.imageBlock--inline').each(function(){
            const $el = $(this)
            that.resizeInlineImageBlock($el)
        })
    },

    resizeInlineImageBlock: function($el){
        const $img = $el.find('img')
        const _w = $el.outerWidth()

        let imgSize = null

        if($img.length > 0){
            imgSize = dimensionsFromImageBlock($el)
        }
        
        if(imgSize){
            let nW = _w
            let nH = imgSize.height / imgSize.width * _w

            if($el.hasClass('imageBlock--inline-limitHeight')){
                const limitMultAttr = $el.attr('limit-mult')
                let limitMult = limitMultAttr === 'func' ? this.limitMultAttr($el) : parseFloat(limitMultAttr)
                limitMult = isFinite(limitMult) ? limitMult : 0.6
                nH = Math.min(nH, nW * limitMult)
            }

            $el.css({'height': nH})
        }
    },

    resizeImageBlock: function($el){
        const $img = $el.find('img')
        
        const _w = $el.outerWidth()
        const _h = $el.outerHeight()

        let imgSize = null
        if($img.length > 0){
            imgSize = dimensionsFromImageBlock($el)
        }

        if(imgSize){
            const contain = $el.hasClass('imageBlock--contain')
        
            let nW = _w
            let nH = imgSize.height / imgSize.width * _w
            
            if(
                (!contain && nH < _h)
                || (contain && nH > _h)
            ){
                nH = _h
                nW = imgSize.width / imgSize.height * _h 
            }

            if($img.length > 0){
                $img.css({
                    'width': nW,
                    'height': nH,
                    'marginLeft': (nW * -0.5),
                    'marginTop': (nH * -0.5)
                })
            }
        }
    },

    onImageBlockLoad: function($el, width, height){
        const $img = $el.find('img')
        if($img.length > 0){
            $img.attr('width', width)
            $img.attr('height', height)
        }

        this.resizeInlineImageBlock($el)
        this.resizeImageBlock($el)

        if($el.closest('.grid').length > 0){
            const $grid = $el.closest('.grid')
            const gridView = this.findViewForID(this.grids, $grid.attr('grid-id'))
            if(gridView){ gridView.onImageLoad() }
        }
    },

    scroll: function(){

    },

    findViewForID: function(arr, id){
        const item = _.findWhere(arr, {id: id})
        if(item){
            return item.view
        } else {
            return null
        }
    },

    fade: function($el, display, delay, cb, displayBlock = 'block', initCb = null){
        const hasInitCb = initCb && _.isFunction(initCb) ? true : false
        let hasTriggeredInitCb = false

        //Velocity($el, 'stop')
        Velocity($el, {
            opacity: display ? 1 : 0
        }, {
            duration: display ? 600 : 300,
            display: display ? displayBlock : 'none',
            delay: _.isFinite(delay) ? delay : 0,
            queue: false,
            progress: () => {
                if(hasInitCb && !hasTriggeredInitCb){
                    initCb()
                    hasTriggeredInitCb = true
                }
            },
            complete: () => {
                if(cb && _.isFunction(cb)){
                    cb()
                }
            }
        })
    },

    animateIn: function(){
        const promise = $.Deferred()
        
        this.$el.addClass('page--viewIn')

        setTimeout(() => {
            promise.resolve()
            this.pubSub.trigger('page:loaded')
        }, 800)
        return promise.promise()
    },

    animateOut: function(){
        const promise = $.Deferred()

        this.pubSub.trigger('page:removing')
        this.$el.removeClass('page--viewIn page--loaded')
        setTimeout(function(){
            promise.resolve()
        }, 800);
        return promise.promise()
    },

    pageLoaded: function(){
        this.$el.addClass('page--loaded')
    },

    isHomepage: function(){ return false },
    hasAttr: function(attr){
        return !!this.$el.attr(attr)
    },
    hasColour: function(){ return this.hasAttr('page-colour') },
    pageColour: function(){
        if(this.hasColour()){
            return this.$el.attr('page-colour')
        } else {
            return null
        }
    },
    logoColour: function(){
        return this.hasAttr('logo-colour') && this.$el.attr('logo-colour') === 'white' ? 'white' : 'black'
    },

    limitMultAttr: function($el){
        return null
    },

    contentHeight: function(){
        const headerHeight = TAVOLOZZA.view.getHeaderHeight()
        const footerHeight = TAVOLOZZA.view.w <= breakpoints.mobile ? 0 : 18

        return TAVOLOZZA.view.h - (headerHeight + footerHeight)
    },

    functionOnGroup: function(group, cb){
        _.each(group, (_child, _i) => {
            if(_child.view && _.isFunction(_child.view[cb])){
                _child.view[cb]()
            } else if(_.isFunction(_child[cb])){
                _child[cb]()
            }
        })
    },

    removeView: function(){
        const that = this

        _.each([
            this.grids,
            this.homepagePanels,
            this.forms,
            this.salon
        ], (_set, i) => {
            that.functionOnGroup(_set, 'removeView')
        })
        this.grids = []
        this.forms = []
        this.homepagePanels = []
        this.salon = []

        TAVOLOZZA.pubSub.off('app:scroll', this.scroll, this)
        TAVOLOZZA.pubSub.off('app:resize', this.resize, this)
        TAVOLOZZA.pubSub.off('app:resize:last', this.resizeLast, this)
        TAVOLOZZA.pubSub.off('app:resize:imageBlockFill', this.resizeImageBlocks, this)

        this.pubSub.trigger('page:removed')
        this.undelegateEvents()
        this.$el.remove()
    
        this.remove()
    }
})

export default BasePage