import $ 			from 'jquery'
import _ 			from 'underscore'
import Backbone 	from 'backbone'
import WebFont 		from 'webfontloader'

import fullExtend   from './lib/Backbone.fullExtend'

Backbone.$ = $
Backbone.View.fullExtend = fullExtend

import App			from './components/app'
import Router 		from './router'

const TAVOLOZZA = {}

TAVOLOZZA.pubSub = _.extend({}, Backbone.Events)

$(document).ready(() => {
	TAVOLOZZA.view = new App()
	TAVOLOZZA.view.render()
	TAVOLOZZA.router = new Router()
	Backbone.history.start({pushState: true, hashChange: false, root: '/'})

	WebFont.load({
		fontactive: function(){
			TAVOLOZZA.view.resize()
		}
	})
})

global._ = _
global.$ = global.jQuery = $
global.TAVOLOZZA = TAVOLOZZA