import _ from 'underscore'

export default ($el, cb = null) => {
    const $img = $el.find('img')

    if($img.length > 0){
        let img = new Image()
        img.onload = () => {
            if($el.length > 0){
                $el.addClass('imageBlock--hasLoaded')
                if(cb && _.isFunction(cb)){
                    cb($el, img.width, img.height)
                }
            }
        }
        img.src = $img.attr('src')
    }
}