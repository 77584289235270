import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Velocity from "velocity-animate";
import bridget from "jquery-bridget";
import Isotope from "isotope-layout";
import random from "canvas-sketch-util/random";

Backbone.$ = $;
$.bridget("isotope", Isotope);

Backbone.$ = $;

import breakpoints from "../../lib/breakpoints";
import mapVariable from "../../lib/mapVariable";

const HomepagePanel = Backbone.View.extend({
  events: {},

  rowTemplate: _.template(`
		<div class="homepagePanel-row">
			<% for(let i = fromI; i < toI; i++){ %>
				<%= blockTemplate({
					inMiddle: _.indexOf(inMiddle, i) >= 0,
					block: blocks[i]
				}) %>
			<% } %>
		</div>
		`),

  blockTemplate: _.template(`
		<div class="
			homepagePanel-el
			<% if(inMiddle){ %>homepagePanel-el--inMiddle<% } %>
			<% if(!inMiddle){ %>homepagePanel-el--v-<%= block.v %><% } %>
			homepagePanel-el--h-<%= block.h %>
			<% if(block.paddingL){ %>homepagePanel-el--padd-l<% } %>
			<% if(block.paddingR){ %>homepagePanel-el--padd-r<% } %>
			<% if(block.paddingH){ %>homepagePanel-el--padd-h<% } %>
			"
			>
			<%= block.html %>
		</div>
		`),

  initialize: function (opts) {
    this.parent = opts.parent;

    this.resizeTry = 0;
    this.triesMax = 20;

    this.model = new Backbone.Model({});

    TAVOLOZZA.pubSub.on("app:resize", this.resizeHandler, this);
    TAVOLOZZA.pubSub.on("app:resize:last", this.resizeCheck, this);

    this.setup(true);
  },

  setup: function (init = false) {
    const that = this;

    let blocks = [];
    _.shuffle(this.$(".homepagePanel-block")).each(function () {
      const $el = $(this);

      blocks.push({
        h: random.rangeFloor(3),
        v: random.rangeFloor(3),
        paddingL: random.chance(0.5),
        paddingR: random.chance(0.5),
        hasAll:
          $el.hasClass("homepagePanel-block--press_article") ||
          $el.hasClass("homepagePanel-block--blog_article"),
        html: $el.clone().wrap("<div />").parent().html(),
      });
    });

    const rowCount = 2;
    const count = blocks.length;
    const splitDiv = count / rowCount;
    const split = random.boolean() ? Math.floor(splitDiv) : Math.ceil(splitDiv);

    const rows = [];
    let allRowsInMiddle = [];
    for (let i = 0; i < rowCount; i++) {
      const _lastRow = i >= rowCount - 1;

      const _from = i * split;
      const _to = _lastRow ? count : Math.min(_from + split, count);

      const _inRow = _to - _from;
      const _inMiddle = _inRow % 2 === 0 ? 2 : 1;

      const _inMiddleBefore = Math.floor((_inRow - _inMiddle) / 2);
      const _inMiddleAfter = _inRow - _inMiddle - _inMiddleBefore;

      let _inMiddleArr = [];
      for (let j = _from; j < _to; j++) {
        const _j = j - _from;
        if (_j >= _inMiddleBefore && _j < _to - _from - _inMiddleAfter) {
          _inMiddleArr.push(j);
        }
      }
      allRowsInMiddle.push(..._inMiddleArr);

      if (_to - _from > 0) {
        rows.push({
          fromI: _from,
          toI: _to,
          inMiddle: _inMiddleArr,
          blockTemplate: this.blockTemplate,
          _: _,
        });
      }
    }

    let layoutOK = false;
    let tries = 0;
    while (tries < this.triesMax && !layoutOK) {
      layoutOK = this.layoutOK(blocks, allRowsInMiddle);
      if (!layoutOK) {
        blocks = _.shuffle(blocks);
      }
      tries += 1;
    }

    this.$el.html("");
    for (let i = 0; i < rows.length; i++) {
      this.$el.append(
        this.rowTemplate({
          blocks: blocks,
          ...rows[i],
        })
      );
    }

    this.layout(init);
  },

  layoutOK: function (blocks, inMiddle) {
    let ok = true;
    _.every(inMiddle, (_index, _i) => {
      if (blocks[_index].hasAll) {
        ok = false;
        return false;
      } else {
        return true;
      }
    });
    return ok;
  },

  layout: function (check = true) {
    this.resizeEls();

    this.parent.resizeLast();
    this.parent.resizeImageBlocks();

    if (check) {
      this.resizeCheck();
    }
  },

  resizeHandler: function () {
    this.resizeTry = 0;

    this.resizeEls();
  },

  resizeEls: function () {
    const displayPanels = TAVOLOZZA.view.w > breakpoints.mobile;
    this.$el.toggleClass("homepagePanel--hide", !displayPanels);

    const width = this.$el.outerWidth();
    const height = this.$el.outerHeight();
  },

  resizeCheck: function () {
    let resizeOK = this.resizeOK();

    if (!resizeOK) {
      while (this.resizeTry < this.triesMax && !resizeOK) {
        resizeOK = this.resizeOK();
        if (!resizeOK) {
          this.setup();
        }
        this.resizeTry += 1;
      }
    }
  },

  resizeOK: function () {
    let heightOK = true;

    this.$(".homepagePanel-row").each(function (i) {
      const $row = $(this);
      let rowHeight = $row.outerHeight();
      if (i > 0) {
        rowHeight += 90;
      }

      $row.find(".homepagePanel-el").each(function () {
        const elHeight = $(this).outerHeight() - 24;
        if (elHeight > rowHeight) {
          heightOK = false;
        }
      });
    });

    return heightOK;
  },

  removeView: function () {
    TAVOLOZZA.pubSub.off("app:resize", this.resizeHandler, this);
    TAVOLOZZA.pubSub.off("app:resize:last", this.resizeCheck, this);

    this.undelegateEvents();
    this.$el.remove();
    this.remove();
  },
});

export default HomepagePanel;
