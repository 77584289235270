import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Velocity from "velocity-animate";
import requestFrame from "request-frame";
import TWEEN from "@tweenjs/tween.js";
import random from "canvas-sketch-util/random";
import { lerp, inverseLerp } from "canvas-sketch-util/math";
import jquerymousewheel from "jquery-mousewheel";
import IScroll from "iscroll";

jquerymousewheel($);
Backbone.$ = $;

import breakpoints from "../../lib/breakpoints";
import dimensionsFromImageBlock from "../../lib/dimensionsFromImageBlock";
import mapVariable from "../../lib/mapVariable";
import roundVariable from "../../lib/roundVariable";
import loadImageBlock from "../../lib/loadImageBlock";

const Salon = Backbone.View.extend({
  events: {
    mousemove: "mouseMoveHandler",
    mouseleave: "mouseLeaveHandler",
    "click .salon-item": "salonItemClickHandler",
    "click .salon-nav": "salonNavClickHandler",
    "click .salon-item-showhide": "salonItemShowHideClickHandler",
    touchstart: "touchStartHandler",
    touchmove: "touchMoveHandler",
    touchend: "touchEndHandler",
    touchcancel: "touchEndHandler"
  },

  mobileNavTemplate: _.template(`
		<div class="salon-nav salon-nav--prev"></div>
		<div class="salon-nav salon-nav--next"></div>
		`),

  mobileTitleTemplate: _.template(`
		<div class="salon-mobileTitleScreen">
			<%= html %>
		</div>`),

  titleTemplate: _.template(`
		<div class="salon-title">
			<%= html %>
		</div>`),

  initialize: function(opts) {
    this.parent = opts.parent;

    _.bindAll(
      this,
      "alignToRandom",
      "alignToCenter",
      "alignToEdges",
      "alignToCorners",
      "alignMobile",
      "loopUpdate",
      "mouseWheelHandler"
    );

    this.model = new Backbone.Model({
      scrollX: 0.5,
      mouseDelta: 0,
      scrollShift: 0,
      prevDelta: 0,
      titleWidth: 320,
      openImage: null,
      mobileLayout: false,
      isAnimating: false,
      touchX: null,
      touchXStart: null,
      touchXPrev: null,
      touchDown: false,
      titleHtml: null
    });

    this.$el.on("mousewheel", this.mouseWheelHandler);
    TAVOLOZZA.pubSub.on("app:resize", this.resizeHandler, this);

    this.model.on(
      {
        "change:scrollX": this.scrollUpdate,
        "change:mobileLayout": this.mobileLayoutToggle,
        "change:titleHtml": this.titleHtmlUpdate
      },
      this
    );

    this.setup();
  },

  setup: function() {
    this.tweens = new TWEEN.Group();
    this.places = [];

    this.layout();

    this.setLoop();
  },

  salonItemClickHandler: function(e) {
    e.preventDefault();
    e.stopPropagation();

    const $e = $(e.currentTarget);

    if (this.isMobileLayout()) {
      const isOpen = $e.hasClass("salon-item--mobileDetails-active");

      this.hideMobileDetails();

      if (!isOpen) {
        $e.addClass("salon-item--mobileDetails-active");
      }
    } else {
      const openImageID = this.model.get("openImage");
      if (openImageID) {
        this.closeOpenSalonItem();
      } else {
        this.openSalonItem($e);
      }
    }
  },

  salonItemShowHideClickHandler: function(e) {
    e.preventDefault();
    e.stopPropagation();

    const $e = $(e.currentTarget);

    const $salonItem = $e.closest(".salon-item");
    if ($salonItem.length > 0 && $salonItem.hasClass("salon-item--isOpen")) {
      const toggleBool = $e.hasClass("salon-item-showhide--less");
      $salonItem.toggleClass("salon-item--isOpen-hideText", toggleBool);
    }
  },

  hideMobileDetails: function() {
    this.$(".salon-item--mobileDetails-active").removeClass(
      "salon-item--mobileDetails-active"
    );
  },

  salonNavClickHandler: function(e) {
    e.preventDefault();
    e.stopPropagation();

    const $el = $(e.currentTarget);

    const dir = $el.hasClass("salon-nav--prev") ? -1 : 1;
    this.navMobileDir(dir);
  },

  openSalonItem: function($el) {
    const id = $el.attr("salon-id");
    const place = _.findWhere(this.places, { id: id });

    if (!place || this.isMobileLayout()) {
      return;
    }

    this.model.set("openImage", id);

    const containerWidth = this.model.get("containerWidth");
    const containerHeight = this.model.get("containerHeight");
    const windowWidth = this.model.get("windowWidth");

    const xPerc = this.xPercForPlace(place.place);
    const xDist = xPerc - 0.5;
    const additionalX =
      Math.abs(xDist) * (windowWidth / containerWidth) * (xDist > 0 ? 1 : -1);

    const toX = xPerc + additionalX;

    const openSize = this.openSalonSize($el);

    this.parent.$el.addClass("explore--openImage");
    $el.addClass("salon-item--isOpen");

    this.animateToScrollX(toX, 700);
    this.animateOthersApart(id, openSize, true);

    this.animateOpenImage(
      $el,
      {
        size: openSize,
        position: {
          x:
            place.place.position.x +
            place.place.size.width * 0.5 -
            openSize.width * 0.5,
          y: containerHeight * 0.5 - openSize.height * 0.5 - 50
        }
      },
      600,
      300,
      true,
      () => {
        $el.addClass("salon-item--isOpen-active");
      }
    );
  },

  xPercForPlace: function(place) {
    const containerWidth = this.model.get("containerWidth");
    const containerHeight = this.model.get("containerHeight");
    const windowWidth = this.model.get("windowWidth");

    return inverseLerp(
      0,
      containerWidth,
      place.position.x + place.size.width * 0.5
    );
  },

  closeOpenSalonItem: function() {
    const canScroll = this.model.get("canScroll");
    const id = this.model.get("openImage");

    this.resetOpenImage();

    const $el = this.$('.salon-item[salon-id="' + id + '"]');
    const place = _.findWhere(this.places, { id: id });

    const xPerc = canScroll ? this.xPercForPlace(place.place) : 0.5;

    this.animateToScrollX(xPerc, 700);
    this.animateOthersApart(id, null, false);

    this.animateOpenImage($el, place.place, 600, 0, false);
  },

  animateOpenImage: function($el, place, time, delay, toOpen, cb) {
    const $imgArea = $el.find(".salon-item-image");
    const $img = $imgArea.find(".imageBlock:not(.imageBlock--salonFull)");
    let $imgFull = $imgArea.find(".imageBlock.imageBlock--salonFull");

    if (toOpen) {
      const imageData = {
        src: $imgArea.attr("data-image-full"),
        width: $img.find("img").attr("width"),
        height: $img.find("img").attr("height")
      };
      $imgArea.append(
        _.template(`
				<div class="imageBlock imageBlock--salonFull">
					<img src="<%= src %>" width="<%= width %>" height="<%= height %>" />
				</div>`)(imageData)
      );
      $imgFull = $imgArea.find(".imageBlock.imageBlock--salonFull");
      loadImageBlock($imgFull);

      this.fadeImageEl($img, false, 0);
      this.fadeImageEl($imgFull, true, 600);
    } else {
      if ($imgFull && $imgFull.length > 0) {
        this.fadeImageEl($imgFull, false, 0, () => {
          $imgFull.remove();
        });
      }
      this.fadeImageEl($img, true, 500);
    }

    Velocity($el, "stop");
    Velocity(
      $el,
      {
        width: place.size.width,
        height: place.size.height,
        translateX: place.position.x,
        translateY: place.position.y
      },
      {
        duration: time,
        delay: delay,
        queue: false,
        complete: () => {
          if ($img.length > 0) {
            this.parent.resizeImageBlock($img);
          }
          if ($imgFull.length > 0) {
            this.parent.resizeImageBlock($imgFull);
          }
          if (cb && _.isFunction(cb)) {
            cb();
          }
        }
      }
    );
  },

  fadeImageEl: function($el, bool, delay, cb) {
    Velocity($el, "stop");
    Velocity(
      $el,
      {
        opacity: bool ? 1 : 0
      },
      {
        display: bool ? "block" : "none",
        delay: delay,
        duration: 400,
        progress: () => {
          this.parent.resizeImageBlock($el);
        },
        complete: () => {
          if (cb && _.isFunction(cb)) {
            cb();
          }
        }
      }
    );
  },

  animateOthersApart: function(activeID, openSize, open) {
    const that = this;

    const activePlace = _.findWhere(this.places, { id: activeID });

    const toAdjust = open ? openSize.width * 0.75 : null;

    this.activeImages().each(function() {
      const $el = $(this);
      const _id = $el.attr("salon-id");

      if (_id === activeID) {
        return;
      }

      const _place = _.findWhere(that.places, { id: _id });

      if (_place) {
        that.animateSalonItemApart(
          $el,
          _place.place,
          activePlace.place,
          open,
          toAdjust
        );
      }
    });
    if (this.hasTitleSlide()) {
      that.animateSalonItemApart(
        this.$(".salon-title"),
        this.titleBoxPlace(),
        activePlace.place,
        open,
        toAdjust
      );
    }
  },

  animateSalonItemApart: function($el, place, activePlace, open, toAdjust) {
    let _translateX = null;
    if (open) {
      const _mult = place.position.x > activePlace.position.x ? 1 : -1;
      _translateX = place.position.x + toAdjust * _mult;
    } else {
      _translateX = place.position.x;
    }

    Velocity($el, "stop");
    Velocity(
      $el,
      {
        translateX: _translateX
      },
      {
        queue: false,
        delay: open ? 0 : 300,
        duration: 500
      }
    );
  },

  openSalonSize: function($el) {
    const maxWidth = this.model.get("windowWidth") * 0.75;
    const maxHeight = this.model.get("containerHeight") - 120;

    const imageSize = dimensionsFromImageBlock($el);

    let nW = maxWidth;
    let nH = (imageSize.height / imageSize.width) * nW;

    if (nH > maxHeight) {
      nH = maxHeight;
      nW = (imageSize.width / imageSize.height) * nH;
    }

    return {
      width: nW,
      height: nH
    };
  },

  loopUpdate: function() {
    this.setLoop();
    this.tweens.update();

    const isMobile = this.isMobileLayout();
    const canScroll = this.model.get("canScroll");
    const openImage = this.model.get("openImage");

    const mouseDelta = this.model.get("mouseDelta");
    const touchDown = this.model.get("touchDown");

    const scrollShift = this.model.get("scrollShift");
    const scrollShiftLimit = 0.0001;

    const scrollX = this.model.get("scrollX");

    let shouldSnapToMobileIndex = null;

    if (isMobile && Math.abs(scrollShift) > scrollShiftLimit) {
      const mobileIndex = this.getMobileIndex(scrollX);
      const targetMobileIndex =
        scrollShift > 0 ? Math.ceil(mobileIndex) : Math.floor(mobileIndex);
      const snapPercTrigger = 1 / 3;
      const snapPerc = 1 - Math.abs(targetMobileIndex - mobileIndex);

      if (
        targetMobileIndex >= 0 &&
        targetMobileIndex <= this.mobileCount() &&
        snapPerc < 1 &&
        snapPerc > snapPercTrigger
      ) {
        shouldSnapToMobileIndex = targetMobileIndex;
      }
    }

    if (_.isFinite(shouldSnapToMobileIndex)) {
      this.model.set({
        scrollShift: 0
      });
      this.animateToScrollIndex(shouldSnapToMobileIndex, true);
    } else if (canScroll && !openImage && Math.abs(mouseDelta) > 0) {
      const mouseMult =
        (1 - Math.min(1, Math.max(0, Math.abs(scrollX - 0.5) / 0.5))) / 10;
      const mouseShift = mouseDelta * mouseMult;

      this.model.set({
        scrollX: this.shiftScroll(scrollX, mouseShift)
      });
    } else if (Math.abs(scrollShift) > scrollShiftLimit) {
      const scrollDelay = touchDown ? 0 : 0.25;
      const delayedScrollShift = scrollShift * scrollDelay;

      this.model.set({
        scrollX: this.shiftScroll(scrollX, scrollShift),
        scrollShift:
          Math.abs(delayedScrollShift) > scrollShiftLimit
            ? delayedScrollShift
            : 0,
        touchXPrev: touchDown ? this.model.get("touchX") : null
      });

      this.setSnapTO();
    }
  },

  setLoop: function() {
    this.cancelLoop();

    const request = requestFrame("request");
    this.loopID = request(this.loopUpdate);
  },

  cancelLoop: function() {
    if (this.loopID) {
      const cancel = requestFrame("cancel");
      cancel(this.loopID);
    }
  },

  setSnapTO: function() {
    this.clearSnapTO();

    this.snapTO = setTimeout(() => {
      this.snapScrollX();
    }, 250);
  },

  clearSnapTO: function() {
    if (this.snapTO) {
      clearTimeout(this.snapTO);
    }
  },

  snapScrollX: function() {
    if (this.isMobileLayout()) {
      const mobileIndex = this.roundedMobileIndex();
      this.animateToScrollIndex(mobileIndex);
    } else {
      const openImage = this.model.get("openImage");
      const scrollX = this.model.get("scrollX");

      if (openImage || (scrollX >= 0 && scrollX <= 1)) {
        return;
      }

      const toSnap = Math.min(1, Math.max(0, Math.round(scrollX)));

      this.animateToScrollX(toSnap, 450);
    }
  },

  getMobileIndex: function(_scrollX = null) {
    const sX = _.isFinite(_scrollX) ? _scrollX : this.model.get("scrollX");
    const count = this.mobileCount();
    return sX * count;
  },

  roundedMobileIndex: function(_scrollX = null) {
    return Math.round(this.getMobileIndex(_scrollX));
  },

  navMobileDir: function(dir) {
    const index = this.roundedMobileIndex();
    this.animateToScrollIndex(index + dir);
  },

  animateToScrollIndex: function(index, snap = false) {
    const count = this.mobileCount();
    const _index = Math.max(0, Math.min(count - 1, index));
    const newScrollX = _index / count;
    const scrollX = this.model.get("scrollX");
    const acc = 1 / (count * 100000);

    if (Math.abs(newScrollX - scrollX) > acc) {
      this.animateToScrollX(newScrollX, snap);
    }
  },

  animateToScrollX: function(x, time, snap) {
    const scrollX = this.model.get("scrollX");

    this.tweens.removeAll();
    this.clearSnapTO();
    this.resetTouch();

    this.model.set("isAnimating", true);

    const tween = new TWEEN.Tween(
      {
        i: scrollX
      },
      this.tweens
    )
      .to(
        {
          i: x
        },
        _.isFinite(time) ? time : 600
      )
      .easing(snap ? TWEEN.Easing.Cubic.Out : TWEEN.Easing.Cubic.InOut)
      .onUpdate(({ i }) => {
        this.model.set({
          scrollX: i
        });
      })
      .onComplete(({ i }) => {
        this.model.set({
          scrollX: i,
          isAnimating: false
        });
        this.setSnapTO();
      });
    tween.start();
  },

  scrollUpdate: function(model, scrollX) {
    const canScroll = this.model.get("canScroll");

    let x = null;

    const minMax = this.scrollMinMax();
    x = roundVariable(lerp(minMax.min, minMax.max, scrollX), 1000);

    Velocity.hook(this.$(".salon-strip"), "translateX", `${x}px`);
  },

  forceScrollUpdate: function() {
    this.scrollUpdate(this.model, this.model.get("scrollX"));
  },

  scrollMinMax: function() {
    const canScroll = this.model.get("canScroll");

    const containerWidth = this.model.get("containerWidth");
    const windowWidth = this.model.get("windowWidth");
    const widthDifference = containerWidth - windowWidth;
    const widthMargin = windowWidth * 0.15;
    const minX = canScroll ? 0 : widthMargin;
    const maxX = canScroll ? widthDifference * -1 : widthMargin * -1;

    return {
      min: minX,
      max: maxX
    };
  },

  shiftScroll: function(scrollX, shift) {
    let newScrollX = scrollX + shift;

    if (newScrollX < 0 && shift < 0) {
      newScrollX =
        scrollX - this.extensionAdjustment(shift, Math.abs(newScrollX));
    } else if (newScrollX > 1 && shift > 0) {
      newScrollX = scrollX + this.extensionAdjustment(shift, newScrollX - 1);
    }

    return newScrollX;
  },

  extensionAdjustment: function(shift, total) {
    let l = Math.abs(shift);

    const maxShift = 0.1;
    const percOver = 1 - Math.max(Math.min(shift + total / maxShift, 1), 0.9);

    l = l * percOver;

    return l * 0.5;
  },

  mouseWheelHandler: function(e) {
    const prevDelta = this.model.get("prevDelta");
    const canScroll = this.model.get("canScroll");
    const isAnimating = this.model.get("isAnimating");
    const { deltaX, deltaY, deltaFactor } = e;

    if (this.model.get("openImage")) {
      e.preventDefault();
      e.stopPropagation();

      this.closeOpenSalonItem();
      return;
    }

    if (!canScroll) {
      return;
    }

    const direction = Math.abs(deltaX) > Math.abs(deltaY) ? "x" : "y";
    const deltaMult = direction === "x" ? 1 : -1;
    const deltaValue = direction === "x" ? deltaX : deltaY;
    const delta = deltaValue * deltaMult;
    const deltaAbs = Math.abs(delta);

    if (deltaAbs > 0) {
      e.preventDefault();

      const containerWidth = this.model.get("containerWidth");
      let toScroll =
        this.isMobileLayout() && isAnimating
          ? 0
          : (delta * deltaFactor) / containerWidth;

      if (deltaAbs > prevDelta && toScroll > 0) {
        this.tweens.removeAll();
      }

      const scrollShift = this.model.get("scrollShift");
      this.model.set({
        mouseDelta: 0,
        prevDelta: deltaAbs,
        scrollShift: scrollShift + toScroll
      });
    }
  },

  mouseMoveHandler: function(e) {
    if (this.isMobileLayout()) {
      return;
    }

    const width = this.model.get("windowWidth");
    const x = e.pageX;

    const scrollable = 0.1;
    const mousePerc = x / width;
    let deltaPerc = 0;
    if (mousePerc < scrollable) {
      deltaPerc = (1 - mousePerc / scrollable) * -1;
    } else if (mousePerc > 1 - scrollable) {
      deltaPerc = 1 - (1 - mousePerc) / scrollable;
    }

    this.model.set("mouseDelta", deltaPerc);
  },

  mouseLeaveHandler: function() {
    this.model.set("mouseDelta", 0);
  },

  setMouseWheelTO: function() {
    this.clearMouseWheelTO();

    this.mouseWheelTO = setTimeout(() => {}, 250);
  },

  clearMouseWheelTO: function() {
    if (this.mouseWheelTO) {
      clearTimeout(this.mouseWheelTO);
    }
  },

  touchStartHandler: function(e) {
    if (this.model.get("isAnimating")) {
      return;
    }

    const x = e.originalEvent.touches[0].clientX;

    this.model.set({
      touchX: x,
      touchXStart: x,
      touchXPrev: x,
      touchDown: true
    });
  },

  touchMoveHandler: function(e) {
    e.preventDefault();

    const touchDown = this.model.get("touchDown");
    const isAnimating = this.model.get("isAnimating");

    if (!touchDown || isAnimating) {
      return;
    }

    const xStart = this.model.get("touchXStart");
    const xPrev = this.model.get("touchXPrev");
    const x = e.originalEvent.touches[0].clientX;
    const xDist = xPrev - x;

    const containerWidth = this.model.get("containerWidth");
    const scrollShift = this.model.get("scrollShift");
    const toScroll = xDist / containerWidth;

    this.model.set({
      touchX: x,
      scrollShift: scrollShift + toScroll
    });
  },

  touchEndHandler: function(e) {
    const touchDown = this.model.get("touchDown");

    this.resetTouch();
  },

  resetTouch: function() {
    this.model.set({
      touchX: null,
      touchXStart: null,
      touchDown: false
    });
  },

  resizeHandler: function() {
    this.layout(true);
  },

  clearResizeTO: function() {
    if (this.resizeTO) {
      clearTimeout(this.resizeTO);
    }
  },

  layout: function(delay = false) {
    this.clearResizeTO();

    this.setDimensions();

    if (this.$(".salon-mobileTitleScreen").length > 0) {
      const $title = this.$(".salon-mobileTitleScreen");
      const windowWidth = this.model.get("windowWidth");
      const containerHeight = this.mobileContainerHeight();

      $title
        .css({
          width: windowWidth,
          height: containerHeight
        })
        .addClass("salon-mobileTitleScreen--hasSet");
    }

    if (delay) {
      this.resizeTO = setTimeout(() => {
        this.layoutImages();
      }, 250);
    } else {
      this.layoutImages();
    }
  },

  mobileLayoutToggle: function(model, isMobile) {
    this.$el.toggleClass("salon--isMobile", isMobile);
    this.hideMobileDetails();

    if (isMobile) {
      this.$el.append(this.mobileNavTemplate());
    } else {
      this.$(".salon-nav").remove();
    }

    this.model.set({
      scrollShift: 0,
      mouseDelta: 0
    });
    this.resetTouch();

    this.layout(true);
  },

  titleHtmlUpdate: function(model, html) {
    const that = this;

    this.getTitleEls().each(function() {
      const $_title = $(this);
      if ($_title) {
        const _iscroll = that.getIScroll($_title);
        if (_iscroll) {
          _iscroll.destroy();
        }
        $_title.remove();
      }
    });

    if (html) {
      this.$(".salon-strip").append(this.mobileTitleTemplate({ html: html }));
      this.$(".salon-strip").append(this.titleTemplate({ html: html }));
      this.getTitleEls().each(function() {
        const $_title = $(this);
        const $_desc = $_title.find(".exploreDetail-detail-description");
        if ($_desc) {
          const _el = $_desc[0];
          const _iscroll = new IScroll(_el, {
            mouseWheel: true,
            scrollbars: true,
            probeType: 2,
            preventDefault: false,
            eventPassthrough: false,
            resizeScrollbars: false,
            scrollbars: "custom"
          });
          $_desc.data("iscroll", _iscroll);
        }
      });
    }
  },

  getTitleEls: function() {
    return this.$(
      ".salon-strip .salon-title, .salon-strip .salon-mobileTitleScreen"
    );
  },

  getIScroll: function($title) {
    const $desc = $title.find(".exploreDetail-detail-description");
    if ($desc) {
      return $desc.data("iscroll");
    } else {
      return null;
    }
  },

  setDimensions: function() {
    const width = this.$el.outerWidth();
    const height = this.$el.outerHeight();

    this.model.set({
      windowWidth: width,
      containerHeight: height,
      gutter: 20,
      margin: 12
    });

    const containerWidth = this.getContainerWidth();
    this.$(".salon-strip").css({ width: containerWidth });

    this.model.set({
      containerWidth: containerWidth,
      containerArea: containerWidth * height
    });
  },

  layoutImages: function() {
    console.log("layout");
    this.tweens.removeAll();
    this.resetOpenImage();

    const that = this;

    this.places = [];

    const isMobile = this.isMobileLayout();
    const activeImages = this.activeImages();

    if (!isMobile && this.hasTitleSlide()) {
      const $title = this.$(".salon-title");
      that.placeItem($title, this.titleBoxPlace());
      $title.addClass("salon-item--hasPlace");
    }

    this.getTitleEls().each(function() {
      const $_title = $(this);
      if ($_title) {
        const _iscroll = that.getIScroll($_title);
        if (_iscroll) {
          _iscroll.refresh();
        }
      }
    });

    const activeImageCount = activeImages ? activeImages.length : 0;
    this.model.set("activeImageCount", activeImageCount);

    if (activeImageCount > 0) {
      activeImages.each(function() {
        const $el = $(this);
        const _id = `${that.places.length}-${random.rangeFloor(100)}`;
        const _place = that.findImagePlace($el);

        const _hasPlace = !!_place.size && !!_place.position;

        if (_hasPlace) {
          that.placeItem($el, _place);
          that.places.push({
            id: _id,
            place: _place
          });
        }

        $el.attr("salon-id", _id);
        $el.toggleClass("salon-item--hasPlace", _hasPlace);
      });
    }

    this.model.set({
      scrollShift: 0,
      scrollX: isMobile ? 0 : 0.5,
      canScroll:
        this.model.get("containerWidth") > this.model.get("windowWidth")
    });

    this.forceScrollUpdate();
    this.parent.resizeImageBlocks();
  },

  placeItem: function($el, place) {
    $el.css({
      width: place.size.width,
      height: place.size.height
    });
    Velocity.hook($el, "translateX", `${place.position.x}px`);
    Velocity.hook($el, "translateY", `${place.position.y}px`);
  },

  titleBoxPlace: function() {
    const cW = this.model.get("containerWidth");
    const cH = this.model.get("containerHeight");
    const titleWidth = this.model.get("titleWidth");

    return {
      size: {
        width: titleWidth,
        height: cH
      },
      position: {
        x: cW * 0.5 - titleWidth * 0.5,
        y: 0
      }
    };
  },

  getContainerWidth: function() {
    const windowWidth = this.model.get("windowWidth");
    const count = this.mobileCount(this.activeLength());

    let w = windowWidth;

    if (this.isMobileLayout()) {
      w = (count + 1) * windowWidth;
    } else {
      const optimimum = 8;

      let mult = count / optimimum;
      mult = Math.max(1, mult);

      w = mult * windowWidth;

      if (count > 3 && this.hasTitleSlide()) {
        w += this.model.get("titleWidth");
      }
    }

    return w;
  },

  activeLength: function() {
    return this.activeImages().length;
  },

  activeImages: function() {
    return this.$(".salon-item:not(.salon-item--filteredOut)");
  },

  allImages: function() {
    return this.$(".salon-item");
  },

  halfPlaceIndex: function() {
    return Math.floor(this.model.get("activeImageCount") / 2);
  },

  sideMultForPlaceIndex: function() {
    const foundPositionAttemptIndex = this.places.length;
    const halfPlaceIndex = this.halfPlaceIndex();
    if (halfPlaceIndex > 0 && foundPositionAttemptIndex >= halfPlaceIndex) {
      return 1;
    } else {
      return -1;
    }
  },

  percForSidePlaceIndex: function() {
    const foundPositionAttemptIndex = this.places.length;
    const halfPlaceIndex = this.halfPlaceIndex();
    const sideMult = this.sideMultForPlaceIndex();

    const min = sideMult < 0 ? 0 : halfPlaceIndex;
    const max =
      sideMult < 0
        ? halfPlaceIndex - 1
        : this.model.get("activeImageCount") - 1;

    return inverseLerp(min, max, foundPositionAttemptIndex);
  },

  findImagePlace: function($el, _attempt) {
    let hasFoundPlace = false;
    let size = null;
    let position = null;
    const foundPositionAttemptIndex = this.places.length;

    let attempt = _attempt;
    if (!_.isFinite(attempt)) {
      attempt = 0;
    }

    size = this.getSizeFromImage($el, attempt);

    let toIterate = [];

    if (this.isMobileLayout()) {
      toIterate.push(this.alignMobile);
    } else {
      if (
        foundPositionAttemptIndex === 0 ||
        foundPositionAttemptIndex === this.halfPlaceIndex()
      ) {
        toIterate.push(this.alignToCenter);
      }

      toIterate.push(this.alignToRandom);

      /*const cornersFirst = random.chance(0.75)
			if(cornersFirst){
				toIterate.push(this.alignToCorners, this.alignToRandom, this.alignToEdges)
			} else {
				toIterate.push(this.alignToRandom, this.alignToCorners, this.alignToEdges)
			}*/

      /*const windowWidth = this.model.get('windowWidth')
			const containerWidth = this.model.get('containerWidth')
			if(containerWidth <= windowWidth){
				toIterate.push(this.alignToEdges)
			}*/

      //toIterate.push(this.alignToCorners);
      //toIterate.push(this.alignToEdges);
    }

    const lookup = this.lookupPositions($el, size, toIterate);
    if (lookup) {
      position = lookup;
      hasFoundPlace = true;
    }

    if (!hasFoundPlace) {
      if (attempt > 50) {
        position = null;
      } else {
        return this.findImagePlace($el, attempt + 1);
      }
    }

    return {
      size: size,
      position: position
    };
  },

  lookupPositions: function($el, size, toIterate) {
    let hasFound = false;
    let position = null;

    for (let i = 0; i < toIterate.length; i++) {
      const _iteratee = toIterate[i];
      const _lookup = _.isFunction(_iteratee) ? _iteratee($el, size) : null;
      if (_lookup) {
        hasFound = true;
        position = _lookup;
        break;
      }
    }

    return position;
  },

  tryLookup: function($el, size, triesMax, cb) {
    let position = null;
    let hasFound = false;
    let tries = 1;

    while (tries < triesMax && !hasFound) {
      const _lookup = _.isFunction(cb) ? cb($el, size, tries) : null;
      if (
        !this.collisionTest({
          size: size,
          position: _lookup
        })
      ) {
        hasFound = true;
        position = _lookup;
      }
      tries += 1;
    }

    return hasFound ? position : null;
  },

  alignMobile: function($el, size) {
    const windowWidth = this.model.get("windowWidth");
    const containerHeight = this.mobileContainerHeight();

    let countIndex = this.mobileCount();

    const startX = countIndex * windowWidth;

    return {
      x: startX + this.centerLength(size.width, windowWidth),
      y: this.centerLength(size.height, containerHeight)
    };
  },

  alignToCenter: function($el, size) {
    return this.tryLookup($el, size, 5, ($el, size, attempt) => {
      const cW = this.model.get("containerWidth");
      const cH = this.model.get("containerHeight");
      const gutter = this.model.get("gutter");
      const sideMult = this.sideMultForPlaceIndex();

      let xShift = gutter;

      if (this.hasTitleSlide()) {
        const titleWidth = this.model.get("titleWidth") * 0.55;
        xShift += titleWidth;
      }

      if (sideMult < 0) {
        xShift += size.width;
      }

      return {
        x: xShift * sideMult + cW * 0.5,
        y: this.randomY(size)
      };
    });
  },

  alignToRandom: function($el, size) {
    return this.tryLookup($el, size, 20, ($el, size, attempt) => {
      return {
        x: this.randomX(size, this.percForSidePlaceIndex()),
        y: this.randomY(size)
      };
    });
  },

  alignToEdges: function($el, size) {
    return this.tryLookup($el, size, 20, ($el, size, attempt) => {
      const cW = this.model.get("containerWidth");
      const cH = this.model.get("containerHeight");
      const gutter = this.model.get("gutter");

      let x, y;
      const seed = random.rangeFloor(4);
      switch (seed) {
        // TOP
        case 0:
          y = gutter;
          break;
        // LEFT
        case 1:
          x = gutter;
          break;
        // BOTTOM
        case 2:
          y = cH - gutter - size.height;
          break;
        // RIGHT
        case 3:
          y = cW - gutter - size.width;
          break;
      }

      switch (seed) {
        case 0:
        case 2:
          x = this.randomX(size);
          break;
        case 1:
        case 3:
          y = this.randomY(size);
          break;
      }

      return {
        x: x,
        y: y
      };
    });
  },

  alignToCorners: function($el, size) {
    /*
		otherModels = shuffle(otherModels.slice(0));
        _.each(otherModels, function(_model, _i){
            var tries = 1;
            var corners = _model.get('corners').slice(0);
            if(!foundPlace && corners.length){
                var cornerUsed;
                // TRY CORNERS
                corners = shuffle(corners);
                _.every(corners, function(_corner){
                    var cornerTries = 0;
                    while(cornerTries < 4 && !foundPlace){
                        dimensions = that.getPointFromCorner(bloc, _corner, _model, cornerTries);
                        if(!that.collisionTest({position: dimensions.point, size: dimensions.size})){
                            foundPlace = true;
                            cornerUsed = _corner;
                        }
                        cornerTries++;
                    }
                    return !foundPlace;
                });   
                if(foundPlace && !isNaN(cornerUsed)){
                    corners = _model.get('corners').slice(0);
                    var indexOfFoundCorner = _.indexOf(corners, cornerUsed);
                    corners.splice(indexOfFoundCorner, 1);
                    _model.set('corners', corners);
                }
            }
            
        });*/
    return null;
  },

  randomX: function(size, perc = null) {
    const cW = this.model.get("containerWidth");
    const hW = cW * 0.5;
    const wW = Math.max(this.model.get("windowWidth") * 0.5, size.width * 3);

    const gutter = this.model.get("gutter");

    const sideMult = this.sideMultForPlaceIndex();

    let min = sideMult < 0 ? 0 : hW;
    let max = sideMult < 0 ? hW : cW;

    if (_.isFinite(perc) && wW < cW) {
      const _perc = sideMult < 0 ? 1 - perc : perc;
      min = lerp(min, max - wW, _perc);
      max = min + wW;
    }

    return random.rangeFloor(min + gutter, max - (size.width + gutter));
  },

  randomY: function(size) {
    const cH = this.model.get("containerHeight");
    const gutter = this.model.get("gutter");

    return random.rangeFloor(gutter, cH - size.height - gutter);
  },

  centerLength: function(c, l) {
    return (l - c) / 2;
  },

  collisionTest: function(place) {
    let hit = false;

    if (this.hitsLogo(place)) {
      return true;
    }

    if (this.hasTitleSlide() && this.hitsTextBox(place)) {
      return true;
    }

    if (this.places.length > 0) {
      _.every(this.places, (_place, _i) => {
        if (this.isCollision(place, _place.place)) {
          hit = true;
          return false;
        } else {
          return true;
        }
      });
    }

    return hit;
  },

  isCollision: function(a, b) {
    const cW = this.model.get("containerWidth");
    const cH = this.model.get("containerHeight");
    const gutter = this.model.get("gutter");

    if (
      _.isFinite(a.position.x) &&
      _.isFinite(a.position.y) &&
      (a.position.x + a.size.width + gutter <= b.position.x ||
        a.position.x >= b.position.x + b.size.width + gutter ||
        a.position.y + a.size.height + gutter <= b.position.y ||
        a.position.y >= b.position.y + b.size.height + gutter)
    ) {
      return false;
    } else {
      return true;
    }
  },

  hitsTextBox: function(place) {
    return this.isCollision(place, this.titleBoxPlace());
  },

  hitsLogo: function(place) {
    const cW = this.model.get("containerWidth");
    const cH = this.model.get("containerHeight");

    const length = 120;

    return this.isCollision(place, {
      size: {
        width: length,
        height: length
      },
      position: {
        x: cW * 0.5 - length * 0.5,
        y: cH - length
      }
    });
  },

  getSizeFromImage: function($el, attempt) {
    const imageSize = dimensionsFromImageBlock($el);
    const imageArea = imageSize.width * imageSize.height;
    const margin = this.model.get("margin");

    if (this.isMobileLayout()) {
      const edgeMargin = 18;
      const innerWidth = this.model.get("windowWidth");
      const innerHeight = this.mobileContainerHeight();

      let iW = innerWidth - edgeMargin * 2 - margin;
      let iH = (imageSize.height / imageSize.width) * iW;

      if (iH + margin >= innerHeight) {
        iH = innerHeight - margin;
        iW = (imageSize.width / imageSize.height) * iH;
      }

      return {
        width: iW + margin,
        height: iH + margin
      };
    } else {
      const attemptIncrease = 2.5 + (attempt + 1) * 0.5;

      const sizeMult = 1;
      const lengthMult = Math.max(this.activeLength() - 1, 4);
      const containerArea = this.model.get("containerArea");

      const targetArea =
        containerArea / attemptIncrease / lengthMult / sizeMult;
      const factor = Math.sqrt(targetArea) / Math.sqrt(imageArea);

      return {
        width: Math.floor(imageSize.width * factor) + margin,
        height: Math.floor(imageSize.height * factor) + margin
      };
    }
  },

  filter: function(cb, $titleEl = null) {
    this.hideMobileDetails();
    this.resetOpenImage();
    const $strip = this.$(".salon-strip");

    this.model.set(
      "titleHtml",
      $titleEl && $titleEl.length > 0 ? $titleEl.html() : null
    );

    this.fade($strip, false, 0, () => {
      this.allImages().each(function() {
        const $el = $(this);
        const _active = cb && _.isFunction(cb) ? cb($el) : false;

        $el.removeClass("salon-item--hasPlace");
        $el.toggleClass("salon-item--filteredOut", !_active);
      });
      this.layout();
      this.fade($strip, true, 100);
    });
  },

  resetFilters: function() {
    this.model.set("titleHtml", null);
    this.filter($el => {
      return true;
    });
  },

  resetOpenImage: function() {
    this.model.set("openImage", null);

    this.parent.$el.removeClass("explore--openImage");
    this.$(".salon-item--isOpen").removeClass("salon-item--isOpen");
    this.$(".salon-item--isOpen-active").removeClass(
      "salon-item--isOpen-active"
    );
    this.$(".salon-item--isOpen-hideText").removeClass(
      "salon-item--isOpen-hideText"
    );
  },

  fade: function($el, display, delay, cb) {
    Velocity($el, "stop");
    Velocity(
      $el,
      {
        opacity: display ? 1 : 0
      },
      {
        duration: display ? 600 : 300,
        delay: _.isFinite(delay) ? delay : 0,
        queue: false,
        complete: () => {
          if (cb && _.isFunction(cb)) {
            cb();
          }
        }
      }
    );
  },

  isMobileLayout: function() {
    return this.model.get("mobileLayout");
  },

  mobileContainerHeight: function() {
    return this.model.get("containerHeight") - 52;
  },

  mobileCount: function(_c = null) {
    let count = _.isFinite(_c) ? _c : this.places.length;
    if (this.hasTitleSlide()) {
      count += 1;
    }
    return count;
  },

  hasTitleSlide: function() {
    return !!this.model.get("titleHtml");
  },

  setMobile: function(mobile) {
    this.model.set("mobileLayout", mobile);
  },

  removeView: function() {
    this.tweens.removeAll();

    this.clearResizeTO();
    this.clearMouseWheelTO();
    this.clearSnapTO();
    this.cancelLoop();

    TAVOLOZZA.pubSub.off("app:resize", this.resizeHandler, this);

    this.undelegateEvents();
    this.$el.remove();
    this.remove();
  }
});

export default Salon;
