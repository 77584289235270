import $ 				from 'jquery'
import _ 				from 'underscore'
import Backbone 		from 'backbone'
import Velocity 		from 'velocity-animate'
import bridget 			from 'jquery-bridget'
import Isotope 			from 'isotope-layout'
import random 			from 'canvas-sketch-util/random'

$.bridget('isotope', Isotope)
Backbone.$ = $

import breakpoints 	from '../../lib/breakpoints'
import mapVariable 	from '../../lib/mapVariable'

const Grid = Backbone.View.extend({
	events: {
		
	},

	randomTemplate: _.template(`<div class="grid-item grid-item-random-spacer grid-item--w-<%= width %>"></div>`),
	randomInnerTemplate: _.template(`<div class="grid-item-random-spacer grid-item-random-spacer--w-<%= width %>"></div>`),

	initialize: function(opts){
		this.model = new Backbone.Model({
			hasRandom: this.$el.hasClass('grid--random'),
		})

		TAVOLOZZA.pubSub.on('app:resize:last', this.resizeHandler, this)

		this.setup()
	},

	setup: function(){
		const that = this

		const cols = 24
		const singleW = 6
		const maxRandomW = cols / 2.5
		const maxRandomSpaceW = cols / 6

		if(this.model.get('hasRandom')){
			this.$('.grid-item').each(function(){
				const $el = $(this)

				const _width = random.rangeFloor(singleW, maxRandomW)
				const _alignmentSeed = random.rangeFloor(3)

				$el	
					.addClass('grid-item--random')
					.addClass('grid-item--w-' + _width)
					.addClass('grid-item--random-' + _alignmentSeed)

				const _beforeSpace = random.rangeFloor(4)
				const _afterSpace = random.rangeFloor(4)
				if(_beforeSpace > 0){
					$el.prepend(that.randomInnerTemplate({width: _beforeSpace}))
				}
				if(_afterSpace > 0){
					$el.append(that.randomInnerTemplate({width: _afterSpace}))
				}

				$el.after(that.randomTemplate({width: random.rangeFloor(1, maxRandomSpaceW)}))
			})
		}

		this.$el.isotope({
			itemSelector: '.grid-item',
			masonry: {
				columnWidth: '.grid-item-spacer'
			},
			transitionDuration: 0
		})
	},

	resizeHandler: function(){
		this.layout()
	},

	onImageLoad: function(){
		this.layout()
	},

	layout: function(){
		this.$el.isotope('layout')
	},

	removeView: function(){
		this.$el.isotope('destroy')

		TAVOLOZZA.pubSub.off('app:resize:last', this.resizeHandler, this)

		this.undelegateEvents()
		this.$el.remove()
		this.remove()
	}
})

export default Grid