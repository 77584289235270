import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Velocity from "velocity-animate";
import bridget from "jquery-bridget";
import Isotope from "isotope-layout";
import random from "canvas-sketch-util/random";

Backbone.$ = $;
$.bridget("isotope", Isotope);

Backbone.$ = $;

import breakpoints from "../../lib/breakpoints";
import mapVariable from "../../lib/mapVariable";

const SignupForm = Backbone.View.extend({
  events: {
    'focus input[type="text"]': "signupFormFocusHandler",
    'blur input[type="text"]': "signupFormBlurHandler",
    'change input[type="text"]': "signupFormChangeHandler",
    submit: "signupFormSubmitHandler"
  },

  initialize: function() {},

  signupFormSubmitHandler: function(e) {
    var that = this;

    e.preventDefault();
    e.stopPropagation();

    var $form = $(e.currentTarget);

    if (
      $form.hasClass("subscribeForm--isSubmitting") ||
      $form.hasClass("subscribeForm--hasMessage")
    ) {
      return;
    }

    var $emailInput = $form.find('input[name="email"]');
    if ($emailInput) {
      var emailRegex = new RegExp(
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
      );
      if (!emailRegex.test($emailInput.val())) {
        this.formShowMessage($form, "Invalid email");
        return;
      }
    }

    $form.addClass("subscribeForm--isSubmitting");

    $.ajax({
      type: "POST",
      dataType: "JSON",
      data: {
        action: "tavolozza_signup",
        email: $emailInput.val()
      },
      url: TAVOLOZZA.adminAJAX,
      error: function(err) {
        $form.removeClass("subscribeForm--isSubmitting");
        that.formShowMessage($form, "Error, please try again");
      },
      success: function(data) {
        $form.removeClass("subscribeForm--isSubmitting");
        that.formShowMessage($form, "Thank you for subscribing");
        $emailInput.val("");
        $form.blur();
        that.formRowCheck($form);
      }
    });
  },

  signupFormFocusHandler: function(e) {
    this.formRowCheck($(e.currentTarget).closest("form"));
  },

  signupFormBlurHandler: function(e) {
    this.formRowCheck($(e.currentTarget).closest("form"));
  },

  signupFormChangeHandler: function(e) {
    this.formRowCheck($(e.currentTarget).closest("form"));
  },

  formRowCheck: function($form) {
    $form.find(".subscribeForm-row").each(function() {
      var $input = $('input:not([type="submit"])', this);

      $(this).toggleClass("subscribeForm-row--focused", $input.is(":focus"));
      $(this).toggleClass(
        "subscribeForm-row--hasValue",
        $input.val() && $input.val().length > 0 && $input.val() != " "
      );
    });
  },

  formShowMessage: function($form, message) {
    $form.find(".subscribeForm-message").html(message);
    $form.addClass("subscribeForm--hasMessage");

    setTimeout(function() {
      if ($form.length > 0) {
        $form.removeClass("subscribeForm--hasMessage");
      }
    }, 1000);
  },

  removeView: function() {
    this.undelegateEvents();
    this.$el.remove();
    this.remove();
  }
});

export default SignupForm;
