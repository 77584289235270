import $            from 'jquery'
import _            from 'underscore'
import Backbone     from 'backbone'

const AppRouter = Backbone.Router.extend({
    routes: {
        '*actions': 'defaultAction'
    },

    initialize: function(){
        this.listenTo(this, 'route', this._trackPageView)
    },

    _trackPageView: function(){
        const path = Backbone.history.getFragment()
        // GA HERE
    },

    defaultAction: function(){
        TAVOLOZZA.view.load()
    }
})

export default AppRouter