import $ 				from 'jquery'
import _ 				from 'underscore'
import Backbone 		from 'backbone'
import Velocity 		from 'velocity-animate'
Backbone.$ = $

import Header           from '../header'

import pages  			from '../../pages'

import paddUrl         from '../../lib/paddUrl'

const App = Backbone.View.extend({
	el: 'body',

	events: {
		'click a': 'linkHandler'
	},

	initialize: function(){
        _.bindAll(this, 'resize', 'scroll')
        $(window).on('resize', this.resize)
        $(window).on('scroll', this.scroll)

        TAVOLOZZA.pubSub.on({
            'app:resize:start': this.resizeStart,
            'app:resize:end': this.resizeEnd
        }, this)

    	this.model = new Backbone.Model({
            'isTouch': 'ontouchstart' in document.documentElement,
            'isFirefox': navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
            'hasOverlay': false,
            'pageColour': null,
            'logoColour': 'black'
    	})

        if(this.isTouch()){
            this.$el.addClass('site--isTouch')
        }

        if(this.isFirefox()){
            this.$el.addClass('site--isFirefox')
        }

        this.model.on({
            'change:isResizing': this.isResizingToggle,
            'change:hasOverlay': this.hasOverlayToggle,
            'change:pageColour': this.pageColourToggle,
            'change:logoColour': this.logoColourToggle
        }, this)

        this.logoColourToggle(this.model, this.model.get('logoColour'))
    },

    isTouch: function(){
        return this.model.get('isTouch')
    },

    isFirefox: function(){
        return this.model.get('isFirefox')
    },

    hasOverlayToggle: function(model, hasOverlay){
        this.$el.toggleClass('site--hasOverlay', hasOverlay)
    },

    pageColourToggle: function(model, colour){
        this.$el.attr('site-colour', colour)
    },

    logoColourToggle: function(model, colour){
        this.$el.attr('logo-colour', colour)
    },

    render: function(){
        if(this.$('header').length > 0){
            this.header = new Header({el: this.$('header')})
            this.header.setup()
        }

        this.resize()
    	this.scroll()
    },

    load: function(page){
        const href = this.currentUrl()

        if(this.header){
            this.header.navigationUpdate(href)
        }
        
        if(!this.view){
            const $el = this.$('.page')
            if($el){
                this.view = this.delegatePage($el)
                this.view.animateIn(true)
                this.onLoaded()
            }

            setTimeout(() => {
                this.$el.addClass('site--isSet')
            }, 50)
        } else if(this.view.$('section[section-url="'+href+'"]').length > 0){
            this.scrollToSubPageIfExists()
        } else {
            let promise, $content

            const oldView = this.view
            promise = oldView.animateOut()

            this.$el.addClass('site--isLoading')

            var ajax = $.ajax({
                url: '/'+Backbone.history.fragment,
                error: function (xhr, ajaxOptions, thrownError){
                    let errorHref = window.location.protocol+'//'+window.location.hostname
                    if(window.location.port){
                        errorHref += ':'+window.location.port;
                    }
                    if(xhr.status == 404) {
                        window.location.replace(errorHref+'/'+Backbone.history.fragment)
                    } else {
                        window.location.href = errorHref+'/'+Backbone.history.fragment
                    }
                },
                success: function(data, textStatus, xhr){
                    if(_.isObject(xhr.responseJSON) && xhr.responseJSON.external_redirect){
                        window.location.replace(xhr.responseJSON.url)
                        return
                    }

                    const $html = $('<div></div>').append(data)
                    document.title = $html.find('title:eq(0)').text()
                    $content = $(data).find('.page')
                }
            });

            $.when(promise).done(() => {
                oldView.removeView();
                $('body, html').scrollTop(0)
            });

            $.when(promise, ajax).done(() => {
                this.$('#container').html('')
                this.$('#container').append($content)
                
                const $el = this.$('.page')
                this.view = this.delegatePage($el)

                setTimeout(() => {
                    this.view.animateIn()
                    this.onLoaded()
                }, 10)
            });
        }
    },

    onLoaded: function(){
        this.$el.removeClass('site--isLoading')

        this.model.set('pageColour', this.view.pageColour())
        this.model.set('logoColour', this.view.logoColour())
        
        this.header.pageLoaded(this.view)

        this.scroll();     
        if(!this.scrollToSubPageIfExists(true)){
            $('body, html').scrollTop(0)
        }
    },

    currentUrl: function(){
        return paddUrl(TAVOLOZZA.homeUrls[0]+'/'+Backbone.history.fragment)
    },

    delegatePage: function($el){
        let controller = $el.attr('view-controller')
        if(_.isUndefined(pages[controller])){
            controller = 'Default'
        }
        return new pages[controller]({el: $el})
    },

    linkHandler: function(e, _href){
        let $el, href

        if(!e && _href){
            href = _href
        } else {
            $el = $(e.currentTarget)
            href = $el.attr('href')
            if(
                ($el.attr('target') && $el.attr('target') == '_blank')
                || $el.hasClass('link-reload')
            ){
                return
            }
        }

        let targetHref, internalUrl

        _.each(TAVOLOZZA.homeUrls, function(_url){
            if(internalUrl){ return; }
            const strLength = _url.length
            if(href.substr(0,strLength)==_url && (href.indexOf('wp-content')<0 && href.indexOf('uploads')<0)){
                targetHref = href.substr(strLength)
                internalUrl = true
            }
        });
        if(internalUrl){
            if(e){
                e.preventDefault()
                e.stopPropagation()
            }
            this.navigateToPage(targetHref)
        }
    },

    navigateToPage: function(href){
        TAVOLOZZA.router.navigate(href, {trigger: true})
    },

    resize: _.throttle(function(){
        if(this.resizeTO){ clearTimeout(this.resizeTO) }
        if(!this.model.get('isResizing')){
            TAVOLOZZA.pubSub.trigger('app:resize:start')
        }

        this.w = this.getW()
        this.h = this.getH()

        this.resizeTO = setTimeout(() => {
            TAVOLOZZA.pubSub.trigger('app:resize:end')
        }, 250)
        TAVOLOZZA.pubSub.trigger('app:resize')
        TAVOLOZZA.pubSub.trigger('app:resize:last')
        TAVOLOZZA.pubSub.trigger('app:resize:post')
        TAVOLOZZA.pubSub.trigger('app:resize:imageBlockFill')
    }, 16),

    resizeStart: function(){
        this.model.set('isResizing', true)
    },

    resizeEnd: function(){
        this.model.set('isResizing', false)
        this.scroll()
    },

    isResizingToggle: function(model, resizing){
        this.$el.toggleClass('site--isResizing', resizing)
    },

    getW: function(){
        return $(window).width()
    },

    getH: function(){
        return $(window).height()
    },

    scroll: _.throttle(function(){
        if(this.scrollTO){ clearTimeout(this.scrollTO) }
        
    	const scrollPos = $(window).scrollTop()

        TAVOLOZZA.view.scrollPosition = scrollPos

        this.scrollTO = setTimeout(() => {
            TAVOLOZZA.pubSub.trigger('app:scroll:end')
        }, 250);
        TAVOLOZZA.pubSub.trigger('app:scroll')
        TAVOLOZZA.pubSub.trigger('app:scroll:last')
        TAVOLOZZA.pubSub.trigger('app:scroll:post')
    }, 16),

    scrollbarWidth: function(){
        let outer = document.createElement("div")
        outer.style.visibility = "hidden"
        outer.style.width = "100px"
        outer.style.msOverflowStyle = "scrollbar"

        document.body.appendChild(outer)

        let widthNoScroll = outer.offsetWidth
        outer.style.overflow = "scroll"

        let inner = document.createElement("div")
        inner.style.width = "100%"
        outer.appendChild(inner)       

        let widthWithScroll = inner.offsetWidth

        outer.parentNode.removeChild(outer)

        return widthNoScroll - widthWithScroll
    },

    scrollTo: function($el, jump){
        let time = 1200;
        if(jump){
            if(this.$el.hasClass('site--isSet')){
                time = 50;
            } else {
                time = 150;
            }
        }

        if($el.length <= 0){
            return
        }

        let position = $el.offset().top
        position -= this.getHeaderHeight()
        position += 2


        $('body, html').animate({
            scrollTop: position
        }, time)
    },

    getHeaderHeight: function(){
        let h = 0
        if(this.header){
            h = this.header.getHeight()
        }
        return h
    },

    scrollToSubPageIfExists: function(jump){
        const $el = this.view.$('section[section-url="'+this.currentUrl()+'"]')
        if($el.length > 0){
            this.scrollTo($el, jump)
            return true;
        } else {
            return false;
        }
    }
})

export default App