import Default  		from './_base'
import Blog 			from './blog'
import Homepage 		from './homepage'
import Explore 			from './explore'
import Search 			from './search'

export default {
	Default: 	Default,
	Homepage: 	Homepage,
	Blog: 		Blog,
	Explore: 	Explore,
	Search: 	Search
}