import $                from 'jquery'

export default ($el, video = null) => {
	let w = 0
	let h = 0

	if(video){
		const videoDimensions = video.getVideoDimensions()
		w = videoDimensions.width
		h = videoDimensions.height
	} else {
		const $img = $el.find('img')

		if($img.length > 0){
			w = parseInt($img.attr('width'))
	    	h = parseInt($img.attr('height'))
		}
	}

    return {
    	width: w,
    	height: h
    }
}