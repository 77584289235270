import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Velocity from "velocity-animate";

Backbone.$ = $;

import BasePage from "./_base";

import breakpoints from "../lib/breakpoints";
import dimensionsFromImageBlock from "../lib/dimensionsFromImageBlock";
import loadImageBlock from "../lib/loadImageBlock";

const Blog = BasePage.fullExtend({
  events: {
    "click .blogArticle-image": "imageExpandHandler",
    "click .pressImageExpanded": "imageExpandedHandler",
    "click .pressImageExpanded-image": "imageExpandedImageHandler",
    "click .pressImageExpanded-showhide": "imageExpandedCaptionToggleHandler",
  },

  imageExpandedTemplate: _.template(
    '<div class="pressImageExpanded">\
            <div class="pressImageExpanded-image">\
                <div class="imageBlock">\
                    <img src="<%= src %>" width="<%= width %>" height="<%= height %>" />\
                </div>\
                <div class="pressImageExpanded-showhide pressImageExpanded-showhide--more">\
                    <span>+</span>\
                </div>\
                <% if(caption){ %><div class="caption">\
                    <div class="pressImageExpanded-showhide pressImageExpanded-showhide--less">\
                        <span>-</span>\
                    </div>\
                    <div class="caption-caption"><%= caption %></div>\
                </div><% } %>\
            </div>\
        </div>'
  ),

  initThis: function () {
    TAVOLOZZA.pubSub.on("app:resizeEnd", this.resizeBlog, this);
  },

  imageExpandHandler: function (e) {
    const that = this;

    e.preventDefault();
    e.stopPropagation();

    const $e = $(e.currentTarget);

    this.closeOpenImage(true);

    if (TAVOLOZZA.view.w <= breakpoints.mobile || $e.find("img").length <= 0) {
      return;
    }

    var $img = $e.find("img");
    this.$el.append(
      this.imageExpandedTemplate({
        src: $img.attr("src"),
        width: $img.attr("width"),
        height: $img.attr("height"),
        caption:
          $e.find(".blogArticle-image-caption").length > 0
            ? $e.find(".blogArticle-image-caption").text()
            : false,
      })
    );

    loadImageBlock(this.$(".pressImageExpanded .imageBlock"), null);
    this.resizeExpandedImage();

    Velocity(
      this.$(".pressImageExpanded"),
      {
        opacity: [1, 0],
      },
      {
        display: "block",
        duration: 300,
        complete: function () {
          Velocity(
            that.$(".pressImageExpanded .pressImageExpanded-image"),
            {
              opacity: [1, 0],
            },
            {
              display: "block",
              duration: 600,
            }
          );
        },
      }
    );
  },

  imageExpandedHandler: function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.closeOpenImage();
  },

  imageExpandedImageHandler: function (e) {
    e.preventDefault();
    e.stopPropagation();
  },

  imageExpandedCaptionToggleHandler: function (e) {
    e.preventDefault();
    e.stopPropagation();

    var $e = $(e.currentTarget);

    var showCaption = $e.hasClass("pressImageExpanded-showhide--more");

    console.log(showCaption);

    this.$(".pressImageExpanded").toggleClass(
      "pressImageExpanded--hideCaption",
      !showCaption
    );
  },

  closeOpenImage: function (jump) {
    if (this.$(".pressImageExpanded").length <= 0) {
      return;
    }

    if (jump) {
      this.$(".pressImageExpanded").remove();
    } else {
      Velocity(
        this.$(".pressImageExpanded"),
        {
          opacity: 0,
        },
        {
          duration: 300,
          display: "none",
          complete: function (el) {
            $(el).remove();
          },
        }
      );
    }
  },

  resizeBlog: function () {
    if (TAVOLOZZA.view.w <= breakpoints.mobile) {
      this.closeOpenImage(true);
    }

    this.resizeExpandedImage();
  },

  resizeExpandedImage: function () {
    if (this.$(".pressImageExpanded").length <= 0) {
      return;
    }

    const hMargin = 12;
    const vMargin = 12;
    const bM = 72;
    const tM = 72;

    const $imageBlock = this.$(".pressImageExpanded .imageBlock");

    const dimensions = dimensionsFromImageBlock($imageBlock);
    const maxWidth = TAVOLOZZA.view.w - 36 - hMargin;
    const maxHeight = TAVOLOZZA.view.h - (tM + bM) - vMargin;

    let w = maxWidth;
    let h = (dimensions.height / dimensions.width) * w;

    if (h > maxHeight) {
      h = maxHeight;
      w = (dimensions.width / dimensions.height) * h;
    }

    const expandedWidth = w + hMargin;
    const expandedHeight = h + vMargin;

    this.$(".pressImageExpanded .pressImageExpanded-image").css({
      width: expandedWidth,
      height: expandedHeight,
      "margin-left": expandedWidth * -0.5,
      "margin-top": expandedHeight * -0.5 - (bM - tM) * 0.5,
    });

    this.$(".pressImageExpanded").addClass("pressImageExpanded--positional");
    this.resizeImageBlock($imageBlock);
    this.$(".pressImageExpanded").removeClass("pressImageExpanded--positional");
  },

  onPageRemoved: function () {
    this.closeOpenImage(true);
    TAVOLOZZA.pubSub.off("app:resizeEnd", this.resizeBlog);
  },
});

export default Blog;
