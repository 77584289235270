import $                from 'jquery'
import _                from 'underscore'
import Backbone         from 'backbone'

Backbone.$ = $

import BasePage     from './_base'

const Search = BasePage.fullExtend({

    events: {
        'change .search-search input[name="search"]': 'updateSearchHandler',
        'keyup .search-search input[name="search"]': 'updateSearchHandler',
        'submit .search-search form': 'searchFormSubmitHandler'
    },

    initThis: function(){
        _.bindAll(this, 'doSearchType')

        this.pubSub.on('page:removed', this.onPageRemoved, this)

        this.$('.search-search input[name="search"]').focus()
    },

    updateSearchHandler: function(e){
        this.doSearchType()
    },

    searchFormSubmitHandler: function(e){
        e.preventDefault()
        e.stopPropagation()
    },

    doSearchType: _.debounce(function(){
        const that = this

        const val = this.$('.search .search-search input[name="search"]').val()
        
        this.abortAJAX()

        if(val && val.length > 0){
            this.searchAJAX = $.ajax({
                url: TAVOLOZZA.adminAJAX + '?action=tavolozzasearch&search_term='+val,
                success: (data) => {
                    this.updateSearchAutoFill(val, data.posts)
                }
            })
        } else {
            that.updateSearchAutoFill(val, [])
        }
    }, 100),

    updateSearchAutoFill: function(term, array){
        const $results = this.$('.search-results')
        $results.html(this.searchAutoFillTemplate({value: term, results: array}))
        this.loadImageBlocks($results)
        this.resizeImageBlocks()
    },

    searchAutoFillTemplate: _.template(`
        <% if(!results || results.length <= 0){ %>
            <% if(value){ %>
                <div class="search-noResults">No results found, try searching for something different</div>
            <% } %>
        <% } else { %>
            <div class="cols cols--6">
                <% _.each(results, function(_result){ %>
                    <div class="col search-result">
                        <a href="<%= _result.link %>">
                            <% if(_result.image){ %>
                                <div class="imageBlock">
                                    <img src="<%= _result.image.sizes.inline %>" width="<%= _result.image.sizes['inline-width'] %>" height="<%= _result.image.sizes['inline-height'] %>" />
                                </div>
                            <% } else { %>
                                <div class="textBlock">
                                    <h2><%= _result.title %></h2>
                                    <% if(_result.text){ %>
                                        <p><%= _result.text %></p>
                                    <% } %>
                                </div>
                            <% } %>
                        </a>
                    </div>
                <% }); %>
            </div>
        <% } %>
    `),

    abortAJAX: function(){
        if(this.searchAJAX){
            this.searchAJAX.abort()
            this.searchAJAX = null
        }
    },

    onPageRemoved: function(){
        this.abortAJAX()
    }
})

export default Search