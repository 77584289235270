import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";

Backbone.$ = $;

import BasePage from "./_base";

import breakpoints from "../lib/breakpoints";

const Explore = BasePage.fullExtend({
  events: {
    "click .exploreFilter-filter": "filterHandler",
    "click .exploreFilter-title": "filterTitleHandler",
    "click .explore-mobileToggle": "mobileToggleHandler",
    "click .exploreFilter-reset": "filterResetHandler",
  },

  initThis: function () {
    const salonView = this.salonView();

    TAVOLOZZA.pubSub.on("app:resize", this.resizeExplore, this);
    TAVOLOZZA.pubSub.on("explore:reset", this.exploreReset, this);
    this.pubSub.on("page:removed", this.onPageRemoved, this);

    const hash = window.location.hash;
    const hashParts = hash ? hash.substring(1).split(":") : [];
    let initFilter = null;
    let initImage = null;
    if (hashParts.length >= 2) {
      if (
        hashParts[0] === "image" &&
        salonView.$(`.salon-item[image-id="${hashParts[1]}"]`).length > 0
      ) {
        initImage = `${hashParts[1]}`;
      } else if (
        this.$(`.exploreFilter[filter-set="${hashParts[0]}"]`).length > 0 &&
        this.$(`.exploreFilter-filter[filter-slug="${hashParts[1]}"]`).length >
          0
      ) {
        initFilter = `${hashParts[0]}:${hashParts[1]}`;
      }
    }

    this.model.set({
      filter: initFilter,
      isMobile: false,
      filterActive: false,
      mobileFilterActive: false,
      detailActive: false,
    });

    this.model.on(
      {
        "change:filter": this.filterToggle,
        "change:isMobile": this.mobileToggle,
        "change:filterActive": this.filterActiveToggle,
        "change:mobileFilterActive": this.mobileFilterToggle,
      },
      this
    );

    this.resizeExplore();

    if (initFilter) {
      this.filterToggle(this.model, this.model.get("filter"));
    }
    if (initImage) {
      this.initImageTO = setTimeout(() => {
        const $imageToOpen = salonView.$(
          `.salon-item[image-id="${hashParts[1]}"]`
        );
        salonView.openSalonItem($imageToOpen);
      }, 500);
    }
  },

  mobileToggle: function (model, mobile) {
    this.model.set("mobileFilterActive", false);
  },

  mobileFilterToggle: function (model, active) {
    this.$el.toggleClass("explore--detailActive", active);
  },

  mobileToggleHandler: function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.model.set("mobileFilterActive", !this.model.get("mobileFilterActive"));
  },

  resizeExplore: function () {
    const isMobile = TAVOLOZZA.view.w <= breakpoints.mobile;
    const salonView = this.salonView();

    this.model.set("isMobile", isMobile);
    salonView.setMobile(isMobile);
  },

  filterTitleHandler: function (e) {
    e.preventDefault();
    e.stopPropagation();

    const $e = $(e.currentTarget);

    this.model.set("filterActive", !this.model.get("filterActive"));
  },

  filterHandler: function (e) {
    e.preventDefault();
    e.stopPropagation();

    const $e = $(e.currentTarget);

    const $set = $e.closest(".exploreFilter");
    const set = $set.attr("filter-set");
    const slug = $e.attr("filter-slug");

    const isActive = !$e.hasClass("exploreFilter-filter--active");

    const newFilter = isActive ? `${set}:${slug}` : null;
    const prevFilter = this.model.get("filter");

    if (newFilter === prevFilter) {
      this.hideMobileFilter();
    }

    this.model.set("filter", newFilter);
  },

  filterToggle: function (model, filter) {
    let isActive = !!filter;
    let set = null;
    let slug = null;

    if (filter) {
      const filterParts = filter.split(":");
      if (filterParts.length === 2) {
        set = filterParts[0];
        slug = filterParts[1];
      } else {
        isActive = false;
      }
    }

    window.location.hash = set && slug ? `${set}:${slug}` : "";

    const salonView = this.salonView();
    const $set = this.$(`.exploreFilter[filter-set="${set}"]`);
    const $id = $set.find(`.exploreFilter-filter[filter-slug="${slug}"]`);
    const id = $id.attr("filter-id");

    let filterTitle = null;

    if (isActive) {
      filterTitle = $id.first().text();

      salonView.filter(($el) => {
        const filters = $el.attr(`${set}-ids`);
        let match = false;

        if (filters) {
          const index = _.indexOf(filters.split(","), id);
          match = index >= 0;
        }

        return match;
      }, this.detailForFilter(set, id));
    } else {
      filterTitle = "All";
      salonView.resetFilters();
    }

    this.$el.toggleClass("explore--isFiltered", isActive);

    this.$(".exploreFilter-filter").removeClass("exploreFilter-filter--active");
    if (isActive) {
      $id.addClass("exploreFilter-filter--active");
    }

    this.$(".explore-mobileToggle .explore-mobileToggle-title").html(
      filterTitle
    );

    this.hideFilters();
  },

  filterActiveToggle: function (model, active) {
    this.$el.toggleClass("explore--filterActive", active);

    const height = active ? this.filterActiveBgHeight() : 0;

    Velocity(
      this.$(".explore-headerBg"),
      {
        height: height,
      },
      {
        queue: false,
        duration: active ? 500 : 300,
      }
    );
  },

  filterActiveBgHeight: function () {
    return this.$(".explore-header").outerHeight() + 32;
  },

  filterResetHandler: function (e) {
    e.preventDefault();
    e.stopPropagation();

    this.resetFilter();
  },

  resetFilter: function () {
    if (!this.model.get("filter")) {
      this.hideFilters();
    }

    this.model.set("filter", null);
  },

  exploreReset: function () {
    this.resetFilter();
  },

  salonView: function () {
    return this.findViewForID(
      this.salon,
      this.$(".salon:eq(0)").attr("salon-id")
    );
  },

  detailForFilter: function (set, id) {
    return this.$(
      `.exploreDetail-detail[filter-set="${set}"][filter-id="${id}"]`
    );
  },

  hideFilters: function () {
    this.model.set({
      filterActive: false,
      mobileFilterActive: false,
    });
  },

  contentHeight: function () {
    return "";
  },

  onPageRemoved: function () {
    if (this.initImageTO) {
      clearTimeout(this.initImageTO);
    }
    TAVOLOZZA.pubSub.off("explore:reset", this.exploreReset, this);
    TAVOLOZZA.pubSub.off("app:resize", this.resizeExplore, this);
  },
});

export default Explore;
