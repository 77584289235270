import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import { lerp } from "canvas-sketch-util/math";

Backbone.$ = $;

import BasePage from "./_base";

import breakpoints from "../lib/breakpoints";

const Homepage = BasePage.fullExtend({
  events: {
    "click *": "clickHandler",
  },

  initThis: function () {
    TAVOLOZZA.pubSub.on("app:resize", this.resizeHomepage, this);
    this.pubSub.on("page:loaded", this.onPageLoaded, this);
    this.pubSub.on("page:removed", this.onPageRemoved, this);

    this.header().model.on(
      {
        "change:state": this.updateLayoutForState,
      },
      this
    );

    this.updateLayoutForState();
  },

  onPageLoaded: function () {
    this.fadePanel(this.$(".homepagePanel"), 0);
  },

  header: function () {
    return TAVOLOZZA.view.header;
  },

  clickHandler: function (e) {
    /*if(TAVOLOZZA.view.w <= breakpoints.mobile){
            e.preventDefault()
            e.stopPropagation()

            this.header().toggleMenu(true)
        }*/
  },

  updateLayoutForState: function () {
    const state = this.header().model.get("state");
    const targetState = this.header().model.get("targetState");
    const dimensions = this.header().getLayoutDimensionsForState();

    _.each(
      [
        {
          $el: this.$(".panelBg.panelBg--tavolozza"),
          panelState: 1,
          attrs: ["width", "height", "panelState"],
          i: 0,
        },
        {
          $el: this.$(".panelBg.panelBg--collection"),
          panelState: -1,
          attrs: ["width", "height", "panelState"],
          i: 1,
        },
        {
          $el: this.$(
            ".panelBg.panelBg--tavolozza .tf-logo.tf-logo--tavolozza"
          ),
          attrs: ["x", "y", "scale"],
          i: 3,
        },
        {
          $el: this.$(
            ".panelBg.panelBg--collection .tf-logo.tf-logo--collection"
          ),
          attrs: ["x", "y", "scale"],
          i: 4,
        },
        {
          $el: this.$(
            ".panelBg.panelBg--collection .tf-logo.tf-logo--tavolozza"
          ),
          attrs: ["x", "y", "scale"],
          i: 5,
        },
        {
          $el: this.$(
            ".panelBg.panelBg--tavolozza .tf-logo.tf-logo--collection"
          ),
          attrs: ["x", "y", "scale"],
          i: 6,
        },
      ],
      (_el, _i) => {
        return null;
        const _dimensions = dimensions.dimensions[_el.i];

        if (_.contains(_el.attrs, "width")) {
          _el.$el.css({ width: _dimensions.width });
        }
        if (_.contains(_el.attrs, "height")) {
          _el.$el.css({ height: _dimensions.height });
        }
        if (_.contains(_el.attrs, "x")) {
          Velocity.hook(_el.$el, "translateX", `${_dimensions.x}px`);
        }
        if (_.contains(_el.attrs, "y")) {
          Velocity.hook(_el.$el, "translateY", `${_dimensions.y}px`);
        }
        if (_.contains(_el.attrs, "scale")) {
          Velocity.hook(_el.$el, "scale", `${_dimensions.scale}`);
        }
        if (_.contains(_el.attrs, "panelState") && _.isFinite(_el.panelState)) {
          _el.$el.toggleClass(
            "panelBg--closed",
            state === targetState && _el.panelState === targetState
          );
        }
      }
    );
  },

  updateHomepageState: function (model, state) {
    this.header().toState(state);
  },

  layoutPanel: function ($panel) {
    const id = $panel.attr("panel-id");
    const view = this.findViewForID(this.homepagePanels, id);
    if (view) {
      view.layout();
    }
  },

  fadePanel: function ($panel, delay = 0, cb) {
    $panel.addClass("homepagePanel--active");
    this.fade($panel, true, delay + 600, cb, "flex", () => {
      this.layoutPanel($panel);
    });
  },

  fadeOutPanel: function ($panel, cb) {
    $panel.removeClass("homepagePanel--active");
    this.fade($panel, false, 0, cb, "flex");
  },

  resizeHomepage: function () {
    this.updateLayoutForState();
  },

  isHomepage: function () {
    return true;
  },

  limitMultAttr: function ($el) {
    const defaultLimitMult = this.panelElLimitMult($el);

    if ($el.closest(".homepagePanel").length > 0) {
      const $panel = $el.closest(".homepagePanel");
      const panelView = this.findViewForID(
        this.homepagePanels,
        $panel.attr("panel-id")
      );

      if (panelView) {
        return panelView.resizeTry <= 0
          ? defaultLimitMult
          : lerp(
              defaultLimitMult,
              0.2,
              panelView.resizeTry / panelView.triesMax
            );
      } else {
        return defaultLimitMult;
      }
    } else {
      return defaultLimitMult;
    }
  },

  panelElLimitMult: function ($el) {
    if (
      $el
        .closest(".homepagePanel-block")
        .hasClass(".homepagePanel-block--instagram_post")
    ) {
      return 0.9;
    } else {
      return 0.75;
    }
  },

  onPageRemoved: function () {
    if (this.initTO) {
      clearTimeout(this.initTO);
    }

    TAVOLOZZA.pubSub.off("app:resize", this.resizeHomepage, this);

    this.header().model.off(
      {
        "change:state": this.updateLayoutForState,
        "change:homepageState": this.updateHomepageState,
      },
      this
    );
  },
});

export default Homepage;
