import $ from "jquery";
import _ from "underscore";
import Backbone from "backbone";
import Velocity from "velocity-animate";
import requestFrame from "request-frame";
import BezierEasing from "bezier-easing";
import { lerp, inverseLerp } from "canvas-sketch-util/math";

Backbone.$ = $;

import breakpoints from "../../lib/breakpoints";
import mapVariable from "../../lib/mapVariable";
import paddUrl from "../../lib/paddUrl";
import getUrlParameter from "../../lib/getUrlParameter";

/*
HEADER STATES
0 = Homepage / Initial
1 = Menu
2 = Page
*/

const Header = Backbone.View.extend({
  events: {
    "click .logo a": "logoHandler",
    "click .hamburger": "hamburgerHandler",
    "click .menuPanel a": "sameLinkHandler",
    "click .tf-logo": "sameLinkHandler",
    "click .menuPanel-explore-button": "sameLinkHandler",
  },

  initialize: function () {
    _.bindAll(this, "updateState");

    const initState = this.fromStateInit();

    this.model = new Backbone.Model({
      state: initState,
      targetState: initState,
      prevState: initState,
      menuOpen: false,
      whiteText: false,
      initHash: this.getInitHash(),
    });

    TAVOLOZZA.pubSub.on("app:resize", this.resizeHandler, this);

    this.model.on(
      {
        "change:state": this.onStateUpdate,
        "change:menuOpen": this.menuOpenToggle,
        "change:whiteText": this.whiteTextToggle,
      },
      this
    );

    this.onStateUpdate(this.model, this.model.get("state"));
  },

  setup: function () {},

  getInitHash: function () {
    const hash = window.location.hash;
    if (hash) {
      return hash.replace("#", "");
    }

    const query = getUrlParameter("site");
    if (query) {
      return query;
    } else {
      return null;
    }
  },

  fromStateInit: function () {
    return 0;
  },

  logoHandler: function (e) {
    const $el = $(e.currentTarget);

    if (this.model.get("menuOpen")) {
      this.model.set("menuOpen", false);
    }
  },

  hamburgerHandler: function (e) {
    e.preventDefault();
    e.stopPropagation();

    const menuOpen = this.model.get("menuOpen");
    this.toggleMenu(!menuOpen);
  },

  sameLinkHandler: function (e) {
    const $el = $(e.currentTarget);
    let href = paddUrl($el.attr("href"));

    if (href === TAVOLOZZA.view.currentUrl()) {
      if ($el.hasClass("menuPanel-explore-button")) {
        TAVOLOZZA.pubSub.trigger("explore:reset");
      }
      this.toggleMenu(false);
    }
  },

  toggleMenu: function (menuOpen) {
    this.model.set("menuOpen", menuOpen);

    if (menuOpen) {
      this.toState(1);
    } else {
      this.toState(TAVOLOZZA.view.view.isHomepage() ? 0 : 2);
    }
  },

  resizeHandler: function () {
    this.updateLayoutForState();

    this.$(".menuPanel").css({
      width: TAVOLOZZA.view.w,
      height: TAVOLOZZA.view.h,
    });

    let menuTitleHeight = 0;
    this.$(".menuPanel .menuPanel-col-title")
      .each(function () {
        const $el = $(this);
        $el.css({ height: "" });
        menuTitleHeight = Math.max(menuTitleHeight, $el.outerHeight());
      })
      .css({ height: menuTitleHeight });
  },

  tweenState: function (
    $el,
    cb,
    time,
    delay = 0,
    state,
    currState,
    jump = false
  ) {
    Velocity($el, "stop");
    if (jump) {
      cb(state);
    } else {
      Velocity(
        $el,
        {
          tween: [state, currState],
        },
        {
          queue: false,
          duration: time,
          delay: delay,
          easing: [0.645, 0.045, 0.355, 1],
          progress: (el, perc, remaining, start, tweenValue) => {
            cb(tweenValue);
          },
          complete: (el) => {
            cb(state);
          },
        }
      );
    }
  },

  onStateUpdate: function () {
    const state = this.model.get("state");
    const targetState = this.model.get("targetState");

    this.$el.toggleClass("header--logoVisible", true);

    this.updateLayoutForState();
  },

  toState: function (state = 0, jump = false) {
    const currState = this.model.get("state");

    if (state === currState) {
      return;
    }

    this.model.set({
      targetState: state,
      prevState: this.model.get("targetState"),
    });

    const $el = this.$(".psuedoEl.psuedoEl--all");
    this.tweenState($el, this.updateState, 1200, 0, state, currState, jump);
  },

  updateState: function (state = 0) {
    this.model.set("state", state);
  },

  updateLayoutForState: function () {
    const state = this.model.get("state");
    const targetState = this.model.get("targetState");
    const dimensions = this.getLayoutDimensionsForState();

    const isMobile = TAVOLOZZA.view.w <= breakpoints.mobile;

    _.each(
      [
        {
          $el: this.$(".hamburger"),
          attrs: ["x", "y"],
          key: "hamburger",
        },
        {
          $el: this.$(".tf-logo"),
          attrs: ["x", "y", "state"],
          key: "logo",
        },
        {
          $el: this.$(".tf-logo .tf-logo-icon"),
          attrs: ["scale"],
          key: "logo",
        },
        {
          $el: this.$(".logo"),
          attrs: ["logoFade"],
          key: null,
        },
      ],
      (_el, _i) => {
        const _dimensions = dimensions.dimensions[_el.key];

        if (_.contains(_el.attrs, "x")) {
          Velocity.hook(_el.$el, "translateX", `${_dimensions.x}px`);
        }
        if (_.contains(_el.attrs, "y")) {
          let y = _dimensions.y;
          Velocity.hook(_el.$el, "translateY", `${y}px`);
        }
        if (_.contains(_el.attrs, "scale")) {
          Velocity.hook(_el.$el, "scale", `${_dimensions.scale}`);
        }
        if (_.contains(_el.attrs, "logoFade")) {
          _el.$el.toggleClass("logo--active", targetState !== 0);
        }
        if (_.contains(_el.attrs, "state")) {
          _el.$el.attr("data-state", targetState);
        }
      }
    );
  },

  getLayoutDimensionsForState: function () {
    const state = this.model.get("state");
    const targetState = this.model.get("targetState");
    const prevState = this.model.get("prevState");

    const statePerc = this.getStatePerc();

    const w = TAVOLOZZA.view.w;
    const h = TAVOLOZZA.view.h;

    const layout = {
      w,
      h,
      midW: w * 0.5,
      midH: h * 0.5,
      isMobile: w <= breakpoints.mobile,
      isMobilePortrait: w <= breakpoints.mobilePortrait,
    };

    const hamburgerX = this.xHamburger(
      state,
      targetState,
      prevState,
      statePerc,
      layout
    );
    const hamburgerY = this.yHamburger(
      state,
      targetState,
      prevState,
      statePerc,
      layout
    );

    const iconX = this.xIcon(state, targetState, prevState, statePerc, layout);
    const iconY = this.yIcon(state, targetState, prevState, statePerc, layout);

    const iconScale = this.scaleIcon(
      state,
      targetState,
      prevState,
      statePerc,
      layout
    );

    return {
      perc: statePerc,
      dimensions: {
        hamburger: {
          x: hamburgerX,
          y: hamburgerY,
        },
        logo: {
          x: iconX,
          y: iconY,
          scale: iconScale,
        },
      },
    };
  },

  getStatePerc: function () {
    const state = this.model.get("state");
    const targetState = this.model.get("targetState");
    const prevState = this.model.get("prevState");

    if (state === targetState) {
      return 1;
    } else {
      return inverseLerp(prevState, targetState, state);
    }
  },

  xIcon: function (state, target, prev, perc, layout) {
    if (state === target || perc === 1) {
      return this.xIconForState(target, layout);
    } else {
      return lerp(
        this.xIconForState(prev, layout),
        this.xIconForState(target, layout),
        perc
      );
    }
  },

  xIconForState: function (state, layout) {
    const { isMobilePortrait, w, midW } = layout;

    if (state <= 1) {
      return midW;
    } else {
      const shiftX = isMobilePortrait ? 24 : 42;
      return w - shiftX;
    }
  },

  yIcon: function (state, target, prev, perc, layout) {
    if (state === target || perc === 1) {
      return this.yIconForState(target, layout);
    } else {
      return lerp(
        this.yIconForState(prev, layout),
        this.yIconForState(target, layout),
        perc
      );
    }
  },

  yIconForState: function (state, layout) {
    const { isMobilePortrait, h, midH } = layout;

    if (state === 0 && this.iconCentralHomepage()) {
      return midH;
    } else if (state <= 1) {
      return h - 64;
    } else {
      return isMobilePortrait ? 24 : 36;
    }
  },

  scaleIcon: function (state, target, prev, perc, layout) {
    if (state === target || perc === 1) {
      return this.scaleIconForState(target, layout);
    } else {
      return lerp(
        this.scaleIconForState(prev, layout),
        this.scaleIconForState(target, layout),
        perc
      );
    }
  },

  scaleIconForState: function (state, layout) {
    if (state === 0 && this.iconCentralHomepage()) {
      return 1;
    } else if (state <= 1) {
      return 0.4;
    } else {
      return 0.3;
    }
  },

  iconCentralHomepage: function () {
    return TAVOLOZZA.siteType === "foundation";
  },

  xHamburger: function (state, target, prev, perc, layout) {
    if (state === target || perc === 1) {
      return this.xHamburgerForState(target, layout);
    } else {
      return lerp(
        this.xHamburgerForState(prev, layout),
        this.xHamburgerForState(target, layout),
        perc
      );
    }
  },

  xHamburgerForState: function (state, layout) {
    const { isMobilePortrait } = layout;
    const { w, m } = this.hamburgerLayout();
    if (state === 0) {
      return w * 0.5;
    } else {
      return isMobilePortrait ? m + 8 : w * 0.5 - m;
    }
  },

  yHamburger: function (state, target, prev, perc, layout) {
    if (state === target || perc === 1) {
      return this.yHamburgerForState(target, layout);
    } else {
      return lerp(
        this.yHamburgerForState(prev, layout),
        this.yHamburgerForState(target, layout),
        perc
      );
    }
  },

  yHamburgerForState: function (state, layout) {
    const { isMobilePortrait } = layout;
    const { w, m } = this.hamburgerLayout();
    if (state === 0) {
      return 0;
    } else {
      return isMobilePortrait ? 0 : m * -1;
    }
  },

  hamburgerLayout: function () {
    return {
      w: 94,
      m: 12,
    };
  },

  menuOpenToggle: function (model, menuOpen) {
    this.$el.toggleClass("header--menuOpen", menuOpen);
    TAVOLOZZA.view.model.set("hasOverlay", menuOpen);
  },

  whiteTextToggle: function (model, white) {
    this.$el.toggleClass("header--whiteText", white);
  },

  getHeight: function () {
    return TAVOLOZZA.view.w <= breakpoints.mobilePortrait ? 48 : 72;
  },

  navigationUpdate: function () {
    this.model.set({
      menuOpen: false,
    });
  },

  pageLoaded: function (view) {
    if (!this.model.get("menuOpen")) {
      this.toState(view.isHomepage() ? 0 : 2);
    }
    this.model.set("whiteText", view.hasColour());
  },

  removeView: function () {
    TAVOLOZZA.pubSub.off("app:scroll", this.scrollHandler, this);
    TAVOLOZZA.pubSub.off("app:resize", this.resizeHandler, this);

    this.undelegateEvents();
    this.$el.remove();
    this.remove();
  },
});

export default Header;
